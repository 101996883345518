/* mobile view  */
@media (width< 900px) {
	/*  beginning of responsive navbar */

	.nav-container {
		position: fixed;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 10px 20px;
		flex-wrap: wrap;
		z-index: 2;
		width: 100%;
		background-color: #181818;
		transform: translate(-10px, 0px);
	}
	.nav-item {
		display: none;
	}
	.nav-item.name,
	.nav-item.burger {
		display: flex;
	}
	.nav-item > h1 > a {
		text-decoration: none;
		color: #fff;
		font-size: 18px;
	}
}
/*  End of responsive navbar */

/* desktop view */
@media (width> 900px) {
	.nav-container {
		position: fixed;
		width: 100%;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: space-around;
		text-decoration: none;
		background-color: #181818;
		z-index: 2;
	}
	.nav-item {
		display: flex;
		justify-content: space-evenly;
		width: 100%;
		font-size: 16px;
		color: #fff;
	}
	.nav-item.name h1 > a {
		text-decoration: none;
		color: #fff;
		font-size: 18px;
	}
	.nav-item.info {
		align-items: center;
	}

	.underline:hover {
		-webkit-transition: border-color 0.9s ease;
		transition: border-color 0.9s ease;
	}

	.underline:after {
		content: "";
		display: block;
		width: 0;
		height: 1px;
		background-color: white;
		-webkit-transition: width 0.9s;
		transition: width 0.9s;
	}

	.underline:hover::after {
		width: 100%;
		-webkit-transition: width 0.9s;
		transition: width 0.9s;
	}

	.nav-heading {
		text-decoration: none;
		font-size: 19px;
		color: #fff;
		font-weight: normal;
		padding: 10px;
	}

	.nav-heading.contact {
		display: block;
		background-color: #fff;
		color: black;
		font-weight: bold;
		padding: 10px 14px;
		border-radius: 6px;
		animation: bounce 1s infinite;
	}
	.nav-heading.contact:hover {
		background-color: #dadada;
		transition: all 0.1s;
	}
	@keyframes bounce {
		0%,
		100% {
			transform: translateY(-7%);
			animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
		}
		50% {
			transform: translateY(0);
			animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
		}
	}

	.change-background-on-hover:hover {
		background-color: rgba(226, 226, 226, 0.068);
		border-radius: 6px;
		transition: all 0.1s ease-in-out;
	}

	.links {
		text-decoration: none;
		color: #fff;
	}
	/* .dropdown-menu {
		visibility: hidden;
		position: absolute;
		display: flex;

		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 20px;
		background-color: rgba(36, 36, 36);
		width: 220px;
		height: 200px;
		transform: translateX(-50px);
		border-radius: 6px;
	}
	.dropdown-menu > li {
		align-items: center;
		list-style: none;
		align-items: center;
		font-size: 18px;
		margin: 15px;
		font-weight: normal;
	}
	.dropdown-menu > li > a {
		text-decoration: none;
		color: white;
		font-weight: normal;
	} */

	.test {
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		list-style: none;
		height: 200px;
		width: 200px;
		background-color: rgba(37, 37, 37);

		transform: translateY(150px);
		border-radius: 6px;
		transition-delay: 100ms;
	}
	.test > li > a {
		text-decoration: none;
		color: white;
	}
	.burger-menu {
		display: none;
	}
	.dropdown {
		display: none;
	}
}

@media (width<=900px) {
	.nav-container {
		width: 100%;
		transform: translateX(0px);
		position: fixed;

		margin: 0px;
	}
	.name > a {
		transform: translateX(20px);
		color: #fff;
		text-decoration: none;
	}
	.name {
		font-size: 16px;
		letter-spacing: 1px;
	}
	.burger-menu {
		transform: translateX(-20px);
	}
	.dropdown {
		position: fixed;
		display: flex;
		align-items: center;
		width: 100%;
		height: 270px;
		justify-content: space-around;
		flex-direction: column;
		background-color: #fff;
		transform: translateY(44px);
		z-index: 1;
	}
	.dropdown > li {
		display: flex;
		align-items: center;
		justify-content: center;
		list-style-type: none;
		height: 100%;
		text-align: center;
		width: 100%;
	}
	.dropdown > li:hover {
		background-color: #dadada52;
	}
	.dropdown > li > a {
		list-style-type: none;
		text-decoration: none;
		color: rgba(37, 37, 37);
		font-size: 19px;
	}
}
