@media (width<900px) {
	.home {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
	.home > .art-header-container {
		transform: translateY(40px) !important;
	}
	.home > .art-header-container > * {
		margin: 0px;
		letter-spacing: 1.5px !important;
	}

	.home > .technical-header-container {
		transform: translateY(70px) !important;
		margin: 0px;
		letter-spacing: 1.5px !important;
	}
	.home > .graphic-body > .graphic-header-container {
		letter-spacing: 1.5px !important;
	}
}

@media (width>900px) {
	.home {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
	.home > .art-header-container {
		transform: translateY(100px) !important;
	}
	.home > .art-header-container > * {
		margin: 70px;
	}
}
