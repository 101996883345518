@media (width>900px) {
	.graphic-body {
		transform: translateY(100px);
	}
	.graphic-title {
		transform: translateY(-50px) !important;
		font-size: 28px;
	}
	.graphic-post-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		align-items: baseline;
		padding: 30px;
		height: 100%;
		background-color: rgb(27, 27, 27);
		border-radius: 6px;
		margin: 0px 100px;
		margin-bottom: 110px;
	}
	.graphic-image-container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		height: 400px;
		margin: 70px;
		padding: 50px;
	}
	.graphic-post-img {
		display: flex;
		align-items: center;
		width: 355px;
		height: fit-content;
		margin-top: 20px;
	}
	.graphic-image-container:nth-of-type(5) > .graphic-post-title,
	.graphic-image-container:nth-of-type(6) > .graphic-post-title,
	.graphic-image-container:nth-of-type(7) > .graphic-post-title {
		margin-top: 550px;
	}

	.graphic-post-title {
		margin-top: 450px;
		color: #fff;
	}

	.graphic-image-container:nth-of-type(8) > img {
		width: 600px;
	}

	.graphic-header-container {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.graphic-header-container {
		display: flex;
		margin: 10px;
	}
	.graphic-header-container > h1 {
		margin: 30px;
	}
}
@media (width<=900px) {
	.graphic-post-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: rgb(27, 27, 27);
		border-radius: 6px;
		overflow-x: hidden;
		margin: 10px;
		margin-top: 100px;
	}
	.graphic-image-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		margin: 20px;
	}
	.graphic-post-title {
		color: #fff;
		font-size: 20px;
		margin: 40px;
	}
	.graphic-image-container > img {
		width: 300px;

		height: 100%;
	}
	.graphic-title > a {
		font-size: 28px;
		color: #fff;
	}
	.graphic-header-container {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.graphic-header-container {
		display: flex;
		transform: translateY(50px);
	}
}
